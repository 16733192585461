
import { isEmpty, isEqual } from "lodash"

export function validExiste(value: string) {

    return value !== undefined && value !== null && value !== ''
}

export function validIsEqual(value: string, value2: string) {
    
    return isEqual(value, value2)
}

export function validCorrectEmail(value: string = '') {

    const regExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/

    return value.match(regExp) !== null
}

export function validCorrectMovil(value: string) {

    const regExp = "^[0-9]+$"

    return value.match(regExp) !== null
}

export function validAlMenosUnoMarcado(list: any) {

    return !isEmpty(list)
}

export function validCorrectYear(value: string = '') {

    return value?.length === 4
}

export function validCorrectDNI(value: string) {

    const regExpNIF = "^[0-9]{8}[A-Z]$"

    const regExpNIE = "^[XYZ][0-9]{7}[A-Z]$"

    return value.match(regExpNIF) !== null || value.match(regExpNIE) !== null
}

export function validNIF(nif: string = '') {
    // Expresión regular para validar el formato del NIF
    var formatoValido = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKET]$/i;
  
    // Verificar el formato del NIF
    if (!formatoValido.test(nif)) {
        return false;
    }
  
    // Extraer el número y la letra del NIF
    var numero = parseInt(nif.slice(0, 8), 10);
    var letra = nif.slice(8).toUpperCase();
  
    // Array con las letras de control
    var letrasControl = 'TRWAGMYFPDXBNJZSQVHLCKE';
  
    // Calcular la letra de control
    var letraCalculada = letrasControl.charAt(numero % 23);
  
    // Verificar si la letra calculada coincide con la letra del NIF
    if (letraCalculada === letra) {
        return true;
    } else {
        return false;
    }
  }